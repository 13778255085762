<template>
  <v-container fluid id="PaineisManutencao" class="container-fundo pa-0 pb-1" v-bind:style="{ 'background-color':COR_PRINCIPAL }">
      <v-toolbar-title v-bind:style="{ 'background-color':COR_PRINCIPAL }" class="headline lighten-2">

        <!-- Título da página -->
        <v-toolbar-title class="text-white title-page mt-3 ml-4 w-100"><span >Painéis</span>
        </v-toolbar-title>
        <!-- Fim Título da página -->

      </v-toolbar-title>
      <!-- Fim Botões de ação cabeçalho -->
    
      <v-row class="mt-0 w-100 mx-0 mb-1">
        <!-- Grade ----------------------------------->
        <v-col cols="12" class="pa-0 mx-0 w-100 ">
          <v-container class="container-principal container-rounded px-0 w-100 mx-0">
      
            <v-card :loading="loading" elevation="0" class="card d-flex flex-column pb-0" >
              <v-card-text class="card-content my-0 py-0 px-2">

                <v-row class="wrapper-bottom my-0 py-0 px-1" >
                  <!-- COLUNA ESQUERDA ------------------------------->
                  <v-col style="max-width: 200px;" class="col-esquerda shrink d-none d-sm-flex flex-column rounded-tl-lg rounded-bl-lg pa-0">
                    <div
                      v-if="expand"
                      class="list rounded-bl-lg overflow-y-scroll pt-0" dense>
                      <!-- PESQUISA -------------------->
                      <div
                        class="list mt-2 mb-n4 mx-3"
                        dark
                        elevation="0"
                      >
                        <v-text-field
                        v-model="search"
                        prepend-inner-icon="mdi-magnify"
                        clear-icon="mdi-close-circle-outline"
                        label="Buscar..."
                        background-color="#FFFFFF"
                        solo
                        dense
                        flat
                        class="search-input"
                        ></v-text-field>
                      </div>
                      <!-- FIM PESQUISA -------------------->
                      
                      <!-- Menu Lateral ----------------- -->
                      <v-navigation-drawer width="250">
                        <v-treeview
                          v-model="tree"
                          :open="open"
                          :items="menu_config"
                          :search="search"
                          :filter="filter"
                          item-key="cod_painel"
                          activatable
                          return-object
                          open-on-click
                          open-all
                          class="treeview overflow-y-scroll mx-auto rounded-bl-lg"
                          @update:active="abrePainelExterno2"
                        >
                        <!--  open-all
                              item-key="name"
                        -->
                          <template v-slot:label="{item}">
                            <v-tooltip right >
                              <template v-slot:activator="{ on, attrs }">
                                <div 
                                  v-if="item.descricao"
                                  class="text-menu mb-1 mt-1"
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  <v-icon class="icon-size">mdi-file-document-outline</v-icon>
                                  <span class="text-relatorio text-descricao mt-3 ml-2" style="color: #0548A3">{{item.cod_painel}} - {{item.nome}}</span>
                                  <br>
                                  <p class="text-wrap text-descricao ml-7 mb-n1">{{item.descricao}}</p>
                                  <div class="mt-2 ml-8 " style="border-bottom: 1px solid rgba(0, 0, 0, 0.08);"></div>
                                </div>

                                <div v-else >
                                  <span class="text-relatorio ml-2" style="color: #0548A3; font-weight:600">{{item.nome}}</span>
                                </div>
                              </template>
                              <span>{{item.nome}}</span>
                            </v-tooltip>
                          </template>
                        </v-treeview>                    
                      </v-navigation-drawer>

                    </div>

                    <v-btn
                    class="btn-expanded ma-0 pa-0"
                    @click="expand = !expand; key_painel++"
                    >
                      <v-icon
                      v-if="expand == false"
                      class="size-icon-expanded ma-0 pa-0">
                        mdi-chevron-right
                      </v-icon>

                      <v-icon
                      v-else 
                      class="size-icon-expanded ma-0 pa-0">
                        mdi-chevron-left
                      </v-icon>
                    </v-btn>   

                  </v-col>
                  <!-- FIM COLUNA ESQUERDA ------------------------------->

                  <v-divider class="divisor-vertical" vertical></v-divider>

                  <!-- COLUNA DIREITA ------------------------------->
                  <v-col>
                    <div v-if="tree_node" class="d-flex align-center justify-space-between mt-n3" tabindex="-1" >
                      <div class="d-flex align-center"><p class="font-weight-bold body-1 mt-3 ml-2" v-bind:style="{ 'color':COR_SUBTITULO }">{{tree_node.cod_painel}} - {{tree_node.nome}}</p></div>
                      <div >
                        <!-- BOTAO DUPLICAR ------------------------------------>
                        <v-btn 
                          class="btn text-none mr-4"
                          height="30"
                          elevation="0"
                          :small="isMobile"
                          color="#00A900"
                          @click="PainelDuplicar(tree_node)"
                          outlined>
                          <v-icon class="icon-size mr-2" color="#00A900">mdi-book-plus-multiple-outline</v-icon>
                          <span class="text-none" v-bind:style="{ 'color':COR_SUBTITULO }">Novo painel à partir deste modelo</span>
                        </v-btn>

                        <!-- BOTAO EXCLUIR ------------------------------------>
                        <v-btn 
                          class="btn text-none mr-0"
                          height="30"
                          elevation="0"
                          :small="isMobile"
                          color="red"
                          @click="DialogExcluir(tree_node)"
                          outlined>
                          <v-icon class="icon-size mr-2" color="#FF0000">mdi-trash-can-outline</v-icon>
                          <span class="text-none" v-bind:style="{ 'color':COR_SUBTITULO }">Excluir</span>
                        </v-btn>
                      </div>
                    </div>
                    
                    <v-row>
                      <!-- PAINEL ------------------------------------>
                      <PaineisComponente
                        class="ml-4 mr-n4 mt-4"
                        :key="key_painel"
                        style="width: 100%; height: 100%"
                        ref="PaineisComponente"
                        v-if="tree_node"
                        :Painel="tree_node"
                        :detalhes_="true"
                      />
                      <!-- FIM PAINEL ------------------------------------>
                    </v-row>
                  </v-col>
                  <!-- FIM COLUNA DIREITA ------------------------------->

                </v-row>
              </v-card-text>
            </v-card>

          </v-container>
        </v-col>
        <!-- Fim Grade ----------------------------------->
      </v-row>

    <!-- Modal de Inclusão ou Edição do Painel -->
    <v-dialog
      v-if="dialog_duplicar"
      :value="true"
      persistent
      style="width: 450"
      :max-width="450"
      :retain-focus="false"
    >
      <PaineisManutencaoModal
        :dialog_duplicar.sync="dialog_duplicar"
        :dados_duplicar.sync="dados_duplicar"
        acao_duplicar="I"
        ref="PaineisManutencaoModal"
      />
    </v-dialog>

    <v-dialog
      v-model="dialog_excluir"
      persistent
      max-width="430"
      class="d-flex justify-center"
    >

        <v-card elevation="0" height="150" class="d-flex justify-center">
        <v-card-title class="text-h6">
          Tem certeza que deseja EXCLUIR ?
        </v-card-title>
        <!-- <v-card-text v-if="store_Pessoa.pessoa_selecionado.pessoa_cpf">
          <span style="font-size: 15px; font-weight:600">{{store_Pessoa.pessoa_selecionado.pessoa_nome}}</span><br>
          {{maskCpfCnpj(store_Pessoa.pessoa_selecionado.pessoa_cpf)}}<br>
          {{store_Pessoa.pessoa_selecionado.pessoa_email}}<br>
          {{store_Pessoa.pessoa_selecionado.fone_celular1}}<br>
        </v-card-text>
        <v-card-text v-if="store_Pessoa.pessoa_selecionado.pessoa_cnpj">
          <span style="font-size: 15px; font-weight:600">{{store_Pessoa.pessoa_selecionado.pessoa_nome}}</span><br>
          {{maskCpfCnpj(store_Pessoa.pessoa_selecionado.pessoa_cnpj)}}<br>
          {{store_Pessoa.pessoa_selecionado.pessoa_email}}<br>
          {{store_Pessoa.pessoa_selecionado.fone_celular1}}<br>
        </v-card-text> -->
        <v-card-actions class="d-flex justify-center">
          <v-spacer></v-spacer>
            <v-btn
              id="btn_cancelar"
              ref="btn_cancelar"
              class="mr-4"
              color="primary"
              text
              @click="dialog_excluir = false"
            >
              Cancelar
            </v-btn>

            <v-btn
              class="btn white--text"
              color="primary accent-4"
              :loading="loading_excluir"
              @click="PainelExcluir(cod_painel_excluir)"
            >
              Excluir
            </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


  </v-container>
</template>

<script>
import { COR_PRINCIPAL, COR_SECUNDARIA, COR_SUBTITULO } from "../../services/constantes";
import { API } from "../../services/API"
import { EventBus } from "../../services/eventbus";
import store_site from "../../store/store_site";
import { groupArrayMultKeys } from "../../services/funcoes";
import store_usuario from "../../store/store_usuario"
import { Painel, ListaPaineisGet } from "../../services/global"
import PaineisComponente from './PaineisComponente.vue';
import PaineisManutencaoModal from './PaineisManutencaoModal.vue';

export default {
  name: "PaineisManutencao",

  components: {
    PaineisComponente,
    PaineisManutencaoModal
  },

  data() {

    return { 

      store_site              : store_site,

      ListaPaineisGet         : ListaPaineisGet,
      groupArrayMultKeys      : groupArrayMultKeys,
      
      COR_PRINCIPAL       : COR_PRINCIPAL,
      COR_SUBTITULO       : COR_SUBTITULO,
      COR_SECUNDARIA      : COR_SECUNDARIA,

      dados_duplicar      : null,
      dialog_duplicar     : false,
      dialog_excluir      : false,

      tree                : [],
      tree_node           : null,
      open                : [1, 13],
      search              : null,
      loading             : false,
      loading_excluir     : false,
      cod_painel_excluir  : null,

      menu_config         : [],

      expand              : true,
      key_painel          : 0,

    }
  },

  created() {
		EventBus.$on('lista_paineis', () => {
			this.lista_paineis();
		})
  },

  mounted() {
    // Busca menu para esta aplicacao
    this.lista_paineis();
  },

  computed: {
    isMobile(){
      return this.$vuetify.breakpoint.name==='xs'
    },

    filter () {
      return (item, search, textKey) => {
        if (!item.children) {
          // Este codigo limpa acentos e caracteres especiais "normalize('NFD').replace(/([\u0300-\u036f]|[^0-9a-zA-Z])/g, '')"
          const nome  = item.nome.toLowerCase().normalize('NFD').replace(/([\u0300-\u036f]|[^0-9a-zA-Z])/g, '');
          let descr = '';
          if (item.descricao)
            descr = item.descricao.toLowerCase().normalize('NFD').replace(/([\u0300-\u036f]|[^0-9a-zA-Z])/g, '');
          const searchText = search.toLowerCase().normalize('NFD').replace(/([\u0300-\u036f]|[^0-9a-zA-Z])/g, '');
          return  nome.indexOf(searchText) > -1 ||
                  descr.indexOf(searchText) > -1;
        }
      }
    },
  },

  methods: {

    async lista_paineis() {
      var la_Res = [];
      la_Res = await this.ListaPaineisGet();
      const la_Agrupado = groupArrayMultKeys(la_Res, ['menu_nivel1'])
      this.menu_config = []
      Object.entries(la_Agrupado).forEach((elem) => {
        this.menu_config.push({ cod_painel  : elem[0],
                                nome        : elem[0],
                                children    : elem[1]}
                             )
      })

    },

    async abrePainelExterno2(node) {
      if (node[0]) {
        if (node[0].cod_painel) {

          this.tree_node = null;
          let body = {
            cod_painel: node[0].cod_painel,
            tipo: "json_conteudo",
            parametros: [{ Name: "cod_usuario", Value: store_usuario.user.cod_usuario }],
          };
          let lo_Rel = await Painel(body);
          this.tree_node = {  cod_painel          : node[0].cod_painel,
                              nome                : node[0].nome,
                              descricao           : node[0].descricao,
                              conteudo_stimulsoft : lo_Rel,
                              situacao            : node[0].situacao,
                              menu_nivel1         : node[0].menu_nivel1,
                              menu_nivel2         : node[0].menu_nivel2
                          };
        }
      }  
    },

    // Duplicando Painel
    async PainelDuplicar(node) {
      this.dados_duplicar = {
                              nome                : node.nome,
                              descricao           : node.descricao,
                              situacao            : 'Ativo',
                              conteudo_stimulsoft : node.conteudo_stimulsoft
                            },
      this.dialog_duplicar = true;
    },

    async DialogExcluir(node) {
      this.cod_painel_excluir = node.cod_painel;
      this.dialog_excluir = true;
    },

    async PainelExcluir(cod_painel) {
      this.loading_excluir = true;
      const ls_Rel = await API.delete(`/painel_excluir/${cod_painel}`)
      if (ls_Rel.status == 200) {
        this.lista_paineis();
        this.store_site.alert_cor       = "#00A000",
        this.store_site.alert_timeout   = 1500,
        this.store_site.alert_msg       = ls_Rel.data.result.result.trim();;
        this.store_site.alert           = true;
      } else {
        this.store_site.alert_cor       = "#FF0000",
        this.store_site.alert_timeout   = 10000,
        this.store_site.alert_msg       = ls_Rel.data.errors.trim();
        this.store_site.alert           = true;
      }
      this.loading_excluir = false;
      this.dialog_excluir  = false;
    }

    

  }

};
</script>

<style scoped>
.title-page {
  font-family: "Montserrat", sans-serif;
  letter-spacing: 0px;
}

.text-white {
  color: #FFFFFF;
  letter-spacing: 0px;
}

.date-picker {
  max-width: 200px!important;
}

table tr {
  width: 100%;
}

.table-row {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  background: #fff;
}

.table-row:hover {
  background: #f6f6f6 !important;
}

.v-btn {
  display: inline-block;
}

/*-----------------------------------*/
/*------ RESPONSIVE GENERAL ------*/
/*-----------------------------------*/
.v-data-table-header-mobile {
  background-color: orangered;
  display: none !important;
}

.v-toolbar,
.v-sheet {
  display: flex !important;
  flex-direction: column !important;
  padding: 0px !important;
}

.v-toolbar__content {
  padding-left: 0px !important;
}

.v-toolbar__title {
  padding-right: 0px !important;
  padding-left: 0px !important;
}

.icon-action {
  font-size: 1.3rem !important;
}

.container-principal {
  max-width: 100%;
  margin: auto !important;
}

.container-rounded {
  background: #fff !important;
  border-radius: 12px !important;
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
  font-size: 1.5rem !important;
}

#virtual-scroll-table-tablet {
  display: none;
}

.false-filter {
  background-color:#6C87A2;
  height: 38px!important;
  min-width: 350px!important;
  /* max-width: 900px!important; */
  border-radius: 5px;
}

.false-text {
  width: calc(100%);
  text-align: left;
  font-size: 17px;
  font-weight: 500;
  color: #C9CBCA;
  cursor: pointer;
  overflow-x: hidden;
}

.row {
  width: 100%!important;
}

.false-icon {
  cursor: pointer;
}

.theme--light.v-data-table {
  border-radius: 12px 12px 0px 0px !important;
}

/* ------------------------------------------------------------------------------------------------------------------ */
.text-white {
  color: #FFFFFF;
  letter-spacing: 0px;
}

.nav-link {
  text-decoration: none;
}

.btn-icon:hover {
  background: rgba(255, 255, 255, 0.1);
}

.btn-icon:hover .icon-action {
  color: #FFF!important;
  opacity: 0.8;
}

.card {
  border-radius: 8px;
  /* background: var(--COR_SECUNDARIA); */
  /* box-shadow: var(--COR_SOMBRA)!important; */
}

.card-content, .wrapper-bottom {
  height: calc(100vh - 125px)!important;
}

.divisor {
  height: 100%;
}

.col-esquerda {
  max-width: 300px!important;
  height: 100%;
  position: relative;
  /* background-color: var(--COR_BACKGROUND); */
  margin-top: 0px;
  padding-top: 2px!important;
}

.list {
  /* background-color: var(--COR_BACKGROUND); */
  min-width: 220px!important;
  margin-top: -2px;
  color: var(--COR_BACKGROUND);
}

.v-list-item-content{
  background-color: red;
  min-width: 220px!important;
  margin-top: -2px;
  color: var(--COR_BACKGROUND);
}

.text-descricao {
  font-size: 0.9em;
  color: #A0A0A0;
}

.text-relatorio {
  font-size: 1.0;
  color: #707070;
  font-weight: 500;
}

.size-icon-expanded {
  font-size: 15px;
}

.col-direita {
  background-color: #FFFFFF;
  width: 100%;
  padding: 10px !important;
  margin-left: 8px;
  margin-right: -12px;
}

.icon-size {
  font-size: 20px;
}

.text-right {
  float: right;
}

.v-treeview-node__label {
  overflow: initial;
  text-overflow: initial;
}

.v-navigation-drawer__content {
  overflow-x: auto
}

</style>

